
import axios, { Method } from 'axios'
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function publicApiRequest(method: Method, url: string, data?: unknown): Promise<any> {
    return new Promise((resolve, reject) => {
        const request = axios({
            url: API_BASE_URL + url,
            method,
            data,
        })
        request.then(response => resolve(response.data)).catch(reject)
    })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export async function apiRequest(method: Method, url: string, data?: unknown, contentType: string = 'application/json'): Promise<any> {
    const token = localStorage.getItem('token')
    return new Promise((resolve, reject) => {
        const request = axios({
            url: API_BASE_URL + url,
            method,
            data,
            //  withCredentials: true,
            headers: {
                authorization: `Bearer ${token}`,
                "Content-Type": contentType
            },
        })
        request.then(response => {
            resolve(response.data)
        }).catch(reject)
    }).catch(x => {
        if (x.response.status == 401) {
            localStorage.clear()
            window.location.href = '/';
        }
        return x;
    })
}

// export default axios;
