import { apiRequest } from "../../../utils/axios";
import { User } from "../types/user";

type Response<T> = {
    data: T;
    message: string;
    status: boolean;
    pageInfo: Pageinfo
}

type Pageinfo = {
    totalCount: number,
    totalPages: number,
    currentPage: number,
    pageSize: number,
    hasNext: boolean,
    hasPrevious: boolean
}

export async function getAllUsers(currentPage: number = 1, pageSize: number = 10, searchWord: string = ''): Promise<Response<User[]>> {
    return apiRequest('GET', `/api/users/get-users?CurrentPage=${currentPage}&PageSize=${pageSize}&Search=${searchWord}`);
}

export async function getUser(id: number): Promise<Response<User>> {
    return apiRequest('GET', `/api/users/${id}`, null);
}