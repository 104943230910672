import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { ReactElement } from "react";
import { Toast } from "../../common/components/Toast";
import { PaymentChildComponentProps } from "../types/payment";

const PaymentSearchBar: React.FC<PaymentChildComponentProps> = (props) => {
  // console.log(React.ComponentProps)
  const [search, setSearchWord] = useState("");

  const searchPayment = () => {
    props.getPayments(search);
  };

  return (
    <div className="flex items-center justify-center">
      <div className="relative flex flex-row justify-between mt-24 bg-white w-full h-[128px] rounded-xl">
        <p className="p-4 w-96 h-6 font-inter font-semibold text-lg leading-tight text-blue-700">
          Total Number of Sales: {props?.total}
        </p>
        <div className="flex flex-row mt-16 mx-4">
          <div className="px-4 bg-white dark:bg-gray-900">
            <label className="sr-only">Search</label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-8 font-medium text-base leading-normal text-gray-400"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                onChange={(e) => setSearchWord(e.target.value)}
                id="table-search"
                className="w-80 h-8 block p-2 pl-10 font-medium text-base leading-normal bg-white text-gray-700 border border-blue-700 rounded-3xl w-80 bg-gray-50 focus:ring-blue-700 focus:border-blue-700 dark:bg-gray-700 dark:border-blue-700 dark:placeholder-blue-700 dark:text-white dark:focus:ring-blue-700 dark:focus:border-blue-700"
                placeholder="Search "
              ></input>
            </div>
          </div>
          <div className="mt-2">
            <button
              type="button"
              onClick={() => searchPayment()}
              className="relative mx-2 text-blue-700 bg-gray-300 border border-blue-700 focus:outline-none hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-20 h-7"
            >
              <span className="inline-block mr-3">Search</span>
            </button>
          </div>
        </div>
      </div>
      {/* <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-[680px]  transform overflow-hidden rounded-lg bg-white p-8 text-left align-middle shadow-sm transition-all">
                  <Dialog.Title
                    as="h3"
                    className="ml-12 pb-12 w-96 h-6 font-inter font-semibold text-lg leading-tight text-blue-700"
                  >
                    New Partner
                  </Dialog.Title>
                  <div className="w-full h-auto max-w-lg">
                    <form className="mt-4 mx-12 w-full">
                      <div className="py-3">
                        <input
                          className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="companyName"
                          type="text"
                          placeholder="Company/Office Name"
                          value={getFormFieldValue("companyName")}
                          name="companyName"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="py-2">
                        <input
                          className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="otherName"
                          type="text"
                          placeholder="Other Name"
                          value={getFormFieldValue("otherName")}
                          name="otherName"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="py-2">
                        <input
                          className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="address"
                          type="text"
                          placeholder="Address"
                          value={getFormFieldValue("address")}
                          name="address"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="py-2">
                        <input
                          className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="tinNumber"
                          type="text"
                          placeholder="TIN. Number"
                          value={getFormFieldValue("tinNo")}
                          name="tinNo"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="py-2">
                        <input
                          className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="regNumber"
                          type="text"
                          placeholder="Reg. Number"
                          value={getFormFieldValue("registrationNo")}
                          name="registrationNo"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="py-2">
                        <input
                          className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="regNumber"
                          type="text"
                          placeholder="Phone Number"
                          value={getFormFieldValue("phoneNo")}
                          name="phoneNo"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>
                      <div className="py-2">
                        <input
                          className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          id="contactPersonEmail"
                          type="text"
                          placeholder="Email"
                          value={getFormFieldValue("emailAddress")}
                          name="emailAddress"
                          onChange={(e) => handleFormChange(e)}
                        />
                      </div>

                      <div className="flex py-2 items-center justify-center w-full">
                        <label className="flex flex-col items-center justify-center w-full rounded-lg cursor-pointer py-8 bg-gray-100 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                          {!newPartner?.logoUrl ? (
                            <div className="flex flex-col items-center justify-center pt-5 pb-6">
                              <svg
                                aria-hidden="true"
                                className="w-5 h-5 text-blue-700"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                ></path>
                              </svg>
                              <p className="mb-2 text-sm text-blue-700 dark:text-gray-400">
                                <span className="font-semibold">
                                  Upload Image/Logo
                                </span>{" "}
                              </p>
                            </div>
                          ) : (
                            <img
                              src={newPartner?.logoUrl}
                              height={100}
                              width={100}
                              alt="Partner_Logo"
                            />
                          )}
                          <input
                            id="dropzone-file"
                            type="file"
                            className="hidden "
                            onChange={(e) => handleFileUpload(e)}
                          />
                        </label>
                      </div>
                    </form>
                  </div>
                  <div className="mx-[170px]">
                    <button
                      type="button"
                      className="inline-flex justify-center rounded-md border hover:cursor-pointer border-transparent bg-green-200 bg-opacity-20 px-6 py-2 text-sm font-medium text-green-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={() => handleSavePartner()}
                    >
                      Save changes
                    </button>
                    <span
                      onClick={closeModal}
                      className="mx-4 text-red-600 px-4 underline hover:cursor-pointer"
                    >
                      Cancel
                    </span>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition> */}
      <Toast />
    </div>
  );
};

export default PaymentSearchBar;
