import { Fragment, ReactElement, useState, ChangeEvent } from "react";
import React from "react";
import { useQuery } from "@tanstack/react-query";
import { getAllPayments, updatePaymentDoc } from "../services/api";
import { Payments } from "../types/payment";
import { NumericFormat } from "react-number-format";
import PaymentSearchBar from "../components/paymentSearchBar";
import { toast } from "react-toastify";
import moment from "moment";
import { Pagination, TextInput } from "flowbite-react";
import { Dialog, Transition } from "@headlessui/react";
import { TrashIcon } from "@heroicons/react/24/solid";
import { Confirmation } from "../../common/components/Confirmation";

function PaymentManagement(): ReactElement {
  const [searchWord, setSearchWord] = React.useState("");
  const [payments, setPayments] = useState<Payments[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalSale, setTotalSale] = useState(0);
  const [pageCount, setPageCount] = useState(1);
  const [open, setOpen] = useState<boolean>(false);
  const [selectedPayment, setPayment] = useState<Payments>();
  const [fileNames, setFileNames] = useState<string[]>([]);
  const [files, setFiles] = useState<File[]>([]);
  const [showConfirmation, setShowConfirmation] = useState(false);

  function selectPayment(payment: Payments) {
    setPayment(payment);
    setOpen(true);
  }

  const onPageChange = async (page: number) => {
    const resp = await toast.promise(getAllPayments(20, page, searchWord), {
      pending: "getting payments",
    });
    setTotalSale(resp.pageInfo.totalCount);
    setPageCount(resp.pageInfo.totalPages);
    setPayments(resp.data);
    setCurrentPage(page);
  };

  function handlePolicyChange(e: ChangeEvent<HTMLInputElement>) {
    let update: any = {};
    update["policyNumber"] = e.target.value;
    const updatedValue = { ...selectedPayment, ...update };
    setPayment(updatedValue);
  }

  function handleFileSelection(e: ChangeEvent<HTMLInputElement>) {
    if (e.target.files != undefined) {
      setFileNames([]);
      setFiles([]);
      let selectedNames: string[] = [];
      let files: File[] = [];
      for (var count = 0; count < e.target.files?.length; count++) {
        let file = e.target.files.item(count);
        if (file != null) {
          selectedNames.push(file.name);
          files.push(file);
        }
      }
      setFileNames(selectedNames);
      setFiles(files);
    }
  }

  async function updatePayment() {
    var data = new FormData();
    data.append("policyNo", selectedPayment?.policyNumber ?? "");
    data.append("referenceNo", selectedPayment?.referenceNo ?? "");
    let count = 0;
    files.forEach((x) => data.append(`policyFiles${count++}`, x));

    const resp = await toast
      .promise(updatePaymentDoc(data), {
        pending: "Updating Payment",
        success: "Payment Updated Successfully",
      })
      .catch();
  }

  async function fetchPayments(search: string = "") {
    const resp = await toast.promise(getAllPayments(20, 1, search), {
      pending: "getting payments",
    });
    setSearchWord(search);
    setTotalSale(resp.pageInfo.totalCount);
    setPageCount(resp.pageInfo.totalPages);
    setPayments(resp.data);
  }

  async function removeFile(url: string) {
    setShowConfirmation(true);
  }

  const _ = useQuery(
    ["payments"],
    async () => {
      const resp = await toast.promise(getAllPayments(20, 1), {
        pending: "getting payments",
      });
      setPayments(resp.data);
      setTotalSale(resp.pageInfo.totalCount);
      setPageCount(resp.pageInfo.totalPages);
      return resp.data;
    },
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <div className="flex-1 bg-gray-100 pl-4 pr-4">
        <PaymentSearchBar total={totalSale} getPayments={fetchPayments} />
        <div className="mx-auto mt-4 flex-1 w-full h-auto relative items-center justify-center space-x-4">
          <div className="relative overflow-x-auto rounded-xl">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs border-b text-blue-700 bg-white dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-4">
                    Policy Number
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Policy Holder
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Date
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Product Name
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Reference Number
                  </th>
                  {/* <th scope="col" className="px-6 py-4">
                    Category
                  </th> */}
                  <th scope="col" className="px-6 py-4">
                    Vendor
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Amount
                  </th>
                </tr>
              </thead>
              <tbody>
                {payments?.map((payment) => (
                  <tr
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 cursor-pointer hover:bg-slate-100"
                    onClick={() => selectPayment(payment)}
                  >
                    <th
                      scope="row"
                      className="px-6 py-2 font-medium text-sm text-gray-500 whitespace-nowrap dark:text-white"
                    >
                      {payment?.policyNumber == ""
                        ? "N/A"
                        : payment?.policyNumber}
                    </th>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {`${payment?.firstName} ${payment?.lastName}`}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {moment(payment.paymentDate).format("DD MMM, yyyy")}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {payment.productName}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {payment.referenceNo}
                    </td>
                    {/* <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {payment.category}
                    </td> */}
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {payment.vendor}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {payment.status}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      <NumericFormat
                        value={payment.amount}
                        displayType={"text"}
                        thousandSeparator={true}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <div className="flex overflow-x-auto md:justify-center">
              <Pagination
                className=""
                currentPage={currentPage}
                totalPages={pageCount}
                onPageChange={onPageChange}
              />
            </div>
          </div>
        </div>
      </div>
      {showConfirmation && (
        <Confirmation
          isLoading={true}
          onAccept={() => console.log("accepted")}
          onClose={() => {
            setShowConfirmation(false);
            // setSelectedProductFeature(undefined);
          }}
          isOpen={showConfirmation}
          title={"Warning"}
        >
          {`Are you sure you want to delete ${"file"} feature?`}
        </Confirmation>
      )}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10 "
          onClose={() => setOpen(false)}
        >
          <div className="fixed inset-0 overflow-hidden shadow-2xl">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-[651px]">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900  flex space-x-5">
                          {/* <img
                            src={selectedPartner?.logo}
                            alt="partner_logo"
                            height={100}
                            width={100}
                            className="rounded  border"
                          /> */}
                          Upload Policy Document
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-5">
                        <div>
                          <label className="text-sm">Policy Holder Name</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={`${selectedPayment?.firstName} ${selectedPayment?.lastName}`}
                            name="registrationNo"
                            // onChange={(e) => updateSelectedPartner(e)}
                            disabled
                          />
                        </div>
                        <div>
                          <label className="text-sm">Product Name</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPayment?.productName}
                            name="tinNo"
                            // onChange={(e) => updateSelectedPartner(e)}
                            disabled
                          />
                        </div>
                        <div>
                          <label className="text-sm">Reference Number</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPayment?.referenceNo}
                            name="officialName"
                            // onChange={(e) => updateSelectedPartner(e)}
                            disabled
                          />
                        </div>

                        <div>
                          <label className="text-sm">Vendor</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPayment?.vendor}
                            disabled
                          />
                        </div>
                        <div>
                          <label className="text-sm">Amount</label>
                          {/* <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPayment?.amount}
                            name="amount"
                            disabled
                            // onChange={(e) => updateSelectedPartner(e)}
                          /> */}
                          <NumericFormat
                            value={selectedPayment?.amount}
                            customInput={TextInput}
                            thousandSeparator=","
                            disabled
                          />
                        </div>
                        <div>
                          <label className="text-sm">Policy Number</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPayment?.policyNumber}
                            name="shortName"
                            onChange={(e) => handlePolicyChange(e)}
                          />
                        </div>
                        <div>
                          <label className="text-sm">Uploaded Files</label>
                          <ul className="text-xs text-red-800">
                            {selectedPayment?.fileRecords.map((x) => (
                              <li className="mt-2">
                                <div className="grid grid-cols-2">
                                  <div className="text-center">
                                    <a href={x.fileUrl}>{x.fileName}</a>
                                  </div>
                                  <div>
                                    <TrashIcon
                                      className="text-red-500 h-4 w-5 cursor-pointer"
                                      onClick={() => removeFile(x.fileUrl)}
                                    />
                                  </div>
                                </div>
                              </li>
                            ))}
                          </ul>
                        </div>
                        <div className="flex py-2 items-center justify-center w-full">
                          <label className="flex flex-col items-center justify-center w-full rounded-lg cursor-pointer py-8 bg-gray-100 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600">
                            {
                              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                <svg
                                  aria-hidden="true"
                                  className="w-5 h-5 text-blue-700"
                                  fill="none"
                                  stroke="currentColor"
                                  viewBox="0 0 24 24"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                  ></path>
                                </svg>
                                <p className="mb-2 text-sm text-blue-700 dark:text-gray-400">
                                  <span className="font-semibold">
                                    {fileNames?.length == 0 ? (
                                      <span>Upload Policy Documents</span>
                                    ) : (
                                      fileNames?.map((name) => (
                                        <span>
                                          {name}
                                          <br />
                                        </span>
                                      ))
                                    )}
                                  </span>
                                </p>
                              </div>
                            }
                            <input
                              id="dropzone-file"
                              type="file"
                              className="hidden"
                              multiple
                              onChange={(e) => handleFileSelection(e)}
                            />
                          </label>
                        </div>
                      </div>
                      <div className="mx-[170px]">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border hover:cursor-pointer border-transparent bg-green-500 bg-opacity-20 px-6 py-2 text-sm font-medium text-green-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={() => updatePayment()}
                        >
                          Save Changes
                        </button>
                        <span
                          onClick={() => setOpen(false)}
                          className="mx-4 text-red-600 px-4 underline hover:cursor-pointer"
                        >
                          Cancel
                        </span>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default PaymentManagement;
