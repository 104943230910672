import SideNavBar from "./sideNavBar";
import {Outlet} from "react-router-dom";

export const Layout = () => {
    return (
        <div className="flex">
            <div className="w-[340px]">
                <SideNavBar/>
            </div>
            <div className="flex-1 bg-gray-100 pl-4">
                <Outlet />
            </div>
        </div>
    )
}