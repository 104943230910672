import { apiRequest } from "../../../utils/axios";
import { CreateNewPartner, Partner } from "../types/partners";

type Response<T> = {
    data: T;
    message: string;
    status: boolean;
}

export async function getAllPartners(searchWord: string = '', pageSize: number = 100, currentPage: number = 1): Promise<Response<Partner[]>> {
    return apiRequest('GET', `/api/partners?PageSize=${pageSize}&CurrentPage=${currentPage}&Search=${searchWord}`);
}

export async function addNewPartners(model: FormData): Promise<Response<string>> {
    return apiRequest('POST', '/api/partners', model, "multipart/form-data");
}

export async function updatePartners(model: FormData, partnerId: number | undefined): Promise<Response<string>> {
    return apiRequest('PUT', `/api/partners/${partnerId}`, model, "multipart/form-data");
}