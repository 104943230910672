import { apiRequest } from "../../../utils/axios";
import { CategoryResponseModel, CreateNewPolicy, CreateNewProductFeatureMap, CreateProductFeature, Policy, PolicyClass, PolicyFeatures, Product, ProductFeature, ProductFeatureMap, UpdateProductFeature, UpdateProductFeatureMap } from "../types/product";
import { Response } from "../../common/types/types"
import { Partner } from "../../partners/types/partners";
import { PolicyTypeDto, PolicyTypeEnum } from "../types/policy";

export async function getAllCategories(): Promise<CategoryResponseModel> {
    return apiRequest('GET', '/api/categories', null);
}

export function getPolicyTypes() {
    const data: PolicyTypeDto[] = [
        {
            Id: 1,
            value: "ThirdParty",
            type: PolicyTypeEnum.Motor,
            label: "Third Party"
        },
        {
            Id: 2,
            value: "Comprehensive",
            type: PolicyTypeEnum.Motor,
            label: "Comprehensive"
        },
        {
            Id: 3,
            value: "FuneralCover",
            type: PolicyTypeEnum.Life,
            label: "Funeral Cover"
        },
        {
            Id: 4,
            value: "TermLife",
            type: PolicyTypeEnum.Life,
            label: "Term Life"
        },
        {
            Id: 5,
            value: "Annuity",
            type: PolicyTypeEnum.Life,
            label: "Annuity"
        },
        {
            Id: 6,
            value: "Endowment",
            type: PolicyTypeEnum.Life,
            label: "Endowment"
        }]

    return data;
}

export async function addProductFeature(categoryId: number, productFeature: CreateProductFeature | undefined): Promise<Response<any>> {
    return apiRequest('POST', `/api/categories/${categoryId}/features`, productFeature);
}

export async function getAllFeatures(categoryId: string | undefined): Promise<Response<CreateProductFeature[]>> {
    return apiRequest('GET', `/api/categories/${categoryId}/features`);
}

export async function getAllPolicyCovers(policyId: number | undefined): Promise<Response<PolicyFeatures[]>> {
    return apiRequest('GET', `/api/policies/${policyId}/covers`);
}

export async function updateProductFeature(categoryId: number | undefined, feature: CreateProductFeature | undefined): Promise<Response<any>> {
    return apiRequest('put', `/api/features/${categoryId}`, feature);
}

export async function getAllPolicies(categoryId: string | undefined, currentPage: number = 1, pageSize: number = 10): Promise<Response<Policy[]>> {
    return apiRequest('get', `/api/policies/${categoryId}?PageSize=${pageSize}&CurrentPage=${currentPage}`);
}

export async function updatePolicy(id: number | undefined, policy: any): Promise<Response<string>> {
    return apiRequest('PUT', `/api/policies/${id}`, policy);
}

export async function getAllPartners(): Promise<Response<Partner[]>> {
    return apiRequest('GET', '/api/partners/all', null);
}

export async function getAllPolicyClasses(id: number | string): Promise<Response<PolicyClass[]>> {
    return apiRequest('GET', `/insurance/policyclass/${id}`);
}

export async function addNewPolicy(model: CreateNewPolicy): Promise<Response<string>> {
    return apiRequest('POST', '/api/policies', model);
}

export async function getAllFeatureMaps(): Promise<Response<ProductFeatureMap[]>> {
    return apiRequest('GET', '/insurance/productfeaturemaps', null);
}

export async function addNewProductFeatureMap(model: CreateNewProductFeatureMap): Promise<Response<string>> {
    return apiRequest('POST', '/insurance/productfeaturemaps', model);
}

export async function updateProductFeatureMap(model: UpdateProductFeatureMap): Promise<Response<string>> {
    return apiRequest('PUT', '/insurance/productfeaturemaps', model);
}