import { ReactElement } from "react";

type UserProp = {
  userCount: number;
};

const UserSearchBar: React.FC<UserProp> = (props) => {
  return (
    <div className="flex items-center justify-center">
      <div className="relative flex flex-row justify-between mt-24 bg-white w-full h-[128px] rounded-xl">
        <p className="p-4 w-96 h-6 font-inter font-semibold text-lg leading-tight text-blue-700">
          Total Number of Users: {props.userCount}
        </p>
        <div className="flex flex-row mt-16 mx-4">
          <div className="px-4 bg-white dark:bg-gray-900">
            <label className="sr-only">Search</label>
            <div className="relative mt-1">
              <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                <svg
                  className="w-5 h-8 font-medium text-base leading-normal text-gray-400"
                  aria-hidden="true"
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </div>
              <input
                id="table-search"
                className="w-80 h-8 block p-2 pl-10 font-medium text-base leading-normal bg-white text-gray-400 border border-blue-700 rounded-3xl w-80 bg-gray-50 focus:ring-blue-700 focus:border-blue-700 dark:bg-gray-700 dark:border-blue-700 dark:placeholder-blue-700 dark:text-white dark:focus:ring-blue-700 dark:focus:border-blue-700"
                placeholder="Search "
              ></input>
            </div>
          </div>
          <div className="mt-2">
            <span className="w-116 h-23 font-medium text-base leading-normal text-gray-400">
              Filters:{" "}
            </span>
            <button
              type="button"
              className="relative mx-2 text-orange-700 bg-orange-100 border border-orange-700 focus:outline-none hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-20 h-7"
            >
              <span className="inline-block mr-3">State</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="inline-block w-3 h-3 fill-current text-orange-700 absolute top-1/2 right-3 transform -translate-y-1/2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>
            <button
              type="button"
              className="relative mx-2 text-orange-700 bg-orange-100 border border-orange-700 focus:outline-none hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-20 h-7"
            >
              <span className="inline-block mr-3">LGA</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="inline-block w-3 h-3 fill-current text-orange-700 absolute top-1/2 right-3 transform -translate-y-1/2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                />
              </svg>
            </button>
            {/* <button type="button" className="relative mx-2 text-blue-700 bg-gray-300 border border-blue-700 focus:outline-none hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-20 h-7">
                        <span className="inline-block mr-3">New</span>
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className=" inline-block w-4 h-4 fill-current text-blue-700 absolute top-1/2 right-3 transform -translate-y-1/2">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M12 6v12m6-6H6" />
                            </svg>
                        </button> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserSearchBar;
