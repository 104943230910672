import { Route, Routes } from "react-router-dom";
import "./index.css";
import React, { Dispatch, SetStateAction, useState } from "react";
import AdminDashboard from "./layouts/adminDashboard";
import UserManagement from "./modules/users/screens/userManagement";
import PartnerManagement from "./modules/partners/screens/partnerManagement";
import InsuranceFeatures from "./modules/products/screens/insuranceFeatures";
import InsurancePolicies from "./modules/products/screens/insurancePolicy";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Layout } from "./layouts/layout";
import Login from "./modules/auth/Login";
import PaymentManagement from "./modules/payments/screens/paymentManagement";

export const CategoryContext = React.createContext({
  categories: {} as Partial<any[]>,
  setCategories: {} as Dispatch<SetStateAction<Partial<any[]>>>,
});

function App(): React.ReactElement {
  const client = new QueryClient();
  const [categories, setCategories] = useState<any[]>([]);
  return (
    <QueryClientProvider client={client}>
      <div>
        <Routes>
          <Route path="/" element={<Login />}></Route>
          {/* <CategoryContext.Provider value={{ categories, setCategories }}> */}
          <Route path={"dashboard"} element={<Layout />}>
            <Route path="" element={<AdminDashboard />} />
            <Route path="users" element={<UserManagement />} />
            <Route path="partners" element={<PartnerManagement />} />
            <Route
              path="features/:categoryId"
              element={<InsuranceFeatures />}
            />
            <Route
              path="policies/:categoryId"
              element={<InsurancePolicies />}
            />
            <Route path="payments" element={<PaymentManagement />} />
          </Route>
          {/* </CategoryContext.Provider> */}
        </Routes>
      </div>
    </QueryClientProvider>
  );
}

export default App;
