import React, { ReactElement } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getAllCategories } from "../modules/products/services/api";
import { useQuery } from "@tanstack/react-query";

function SideNavBar(): ReactElement {
  const location = useLocation();
  const navigateTo = useNavigate();

  const getProducts = useQuery(["products"], async () => {
    const { data } = await getAllCategories();
    const categoryInfo = data.map((x) => {
      return {
        name: x.categoryName,
        id: x.id,
      };
    });
    localStorage.setItem("categories", JSON.stringify(categoryInfo));
    return data;
  });

  // eslint-disable-next-line
  const [selectedSection, setSelectedSection] = React.useState(
    location.pathname.includes("dashboard") ? "1" : "1"
  );

  const [activeMenuIndex, setActiveMenuIndex] = React.useState<null | number>(
    null
  );

  React.useEffect(() => {
    const { pathname } = location;
    if (pathname.includes("dashboard")) {
      setSelectedSection("1");
      return;
    }
    if (pathname.includes("users")) {
      setSelectedSection("2");
      return;
    }
    if (pathname.includes("partners")) {
      setSelectedSection("3");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const navigate = (path: string) => {
    navigateTo(path);
  };

  const handleDashboardClick = () => {
    setSelectedSection("1");
    navigate("/dashboard");
  };
  const handleUsersClick = () => {
    setSelectedSection("2");
    navigate("/dashboard/users");
  };
  const handlePartnersClick = () => {
    setSelectedSection("3");
    navigate("/dashboard/partners");
  };

  const handleOptionClick = (index: number) => {
    index === activeMenuIndex
      ? setActiveMenuIndex(null)
      : setActiveMenuIndex(index);
    setSelectedSection("4");
  };

  const handlePolicyClick = (policyId: number) => {
    navigate(`/dashboard/policies/${policyId}`);
    window.location.reload();
  };

  const handleFeatureClick = (policyId: number) => {
    navigate(`/dashboard/features/${policyId}`);
    window.location.reload();
  };

  const logout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <div className="min-h-screen">
      <div className="flex flex-col h-screen py-8 bg-white border-r dark:bg-gray-800 dark:border-gray-600">
        <img
          src={require("../assets/images/chebelogo.png")}
          alt="Chebe Logo"
          className="w-40 h-59 mx-6 -mt-4"
        />
        <hr className="w-86 relative border-b-1"></hr>
        <div className="flex flex-col">
          <div className="mx-4 my-2">
            <h3 className="px-6 py-2 font-inter font-medium tracking-wide text-gray-400 transition-colors duration-200 transform rounded-md dark:text-gray-300">
              GENERAL
            </h3>
            <div
              key="1"
              onClick={handleDashboardClick}
              className="px-6 py-2 font-inter font-medium tracking-wide hover:cursor-pointer text-gray-700 dark:text-gray-400 hover:bg-gray-200 hover:text-blue-400 dark:hover:bg-gray-600 flex items-center rounded-md "
            >
              Dashboard
            </div>
            <div
              key="2"
              onClick={handleUsersClick}
              className="px-6 py-2 font-inter font-medium tracking-wide hover:cursor-pointer text-gray-700 dark:text-gray-400 hover:bg-gray-200 hover:text-blue-400 dark:hover:bg-gray-600 flex items-center rounded-md "
            >
              Users
            </div>
            <div
              key="3"
              onClick={handlePartnersClick}
              className="px-6 py-2 font-inter font-medium tracking-wide hover:cursor-pointer text-gray-700 dark:text-gray-400 hover:bg-gray-200 hover:text-blue-400 dark:hover:bg-gray-600 flex items-center rounded-md "
            >
              Partners (Vendors)
            </div>
            <div
              onClick={() => navigate("/dashboard/payments")}
              className="px-6 py-2 font-inter font-medium tracking-wide hover:cursor-pointer text-gray-700 dark:text-gray-400 hover:bg-gray-200 hover:text-blue-400 dark:hover:bg-gray-600 flex items-center rounded-md "
            >
              Sales
            </div>
          </div>
          <hr className="w-86 relative border-b-1"></hr>
        </div>
        <div className="flex flex-col">
          <div className="mx-4 my-2">
            <h3 className="px-6 py-2 font-inter font-medium tracking-wide text-gray-400 transition-colors duration-200 transform rounded-md dark:text-gray-300">
              PRODUCTS
            </h3>
            {getProducts.status === "success" &&
              getProducts.data?.map((product: any, index: number) => (
                <div>
                  <div
                    key="4"
                    onClick={() => handleOptionClick(index)}
                    className="px-6 py-2 font-inter font-medium tracking-wide text-gray-700 hover:cursor-pointer dark:text-gray-400 hover:bg-gray-200 hover:text-blue-400 dark:hover:bg-gray-600 flex items-center rounded-md "
                  >
                    {product.categoryName}
                    {activeMenuIndex !== index && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="hover:text-blue-400 w-4 h-4 pt-1 ml-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M8.25 4.5l7.5 7.5-7.5 7.5"
                        />
                      </svg>
                    )}
                    {activeMenuIndex === index && (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="text-blue-700 w-4 h-4 pt-1 ml-4"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                        />
                      </svg>
                    )}
                  </div>
                  {activeMenuIndex === index && (
                    <ul className="pl-1 flex flex-col space-y-1">
                      <li
                        onClick={() => handlePolicyClick(product.id)}
                        className="px-5 py-1 font-inter font-normal text-sm text-gray-600 hover:cursor-pointer transition-colors duration-200 transform rounded-md dark:text-gray-300 hover:bg-gray-100 hover:text-blue-400 dark:hover:bg-gray-600 flex items-center rounded-md "
                      >
                        <label className="hover:cursor-pointer">Policy</label>
                      </li>
                      <li
                        onClick={() => handleFeatureClick(product.id)}
                        className="px-5 py-1 font-inter font-normal text-sm text-gray-600 hover:cursor-pointer transition-colors duration-200 transform rounded-md dark:text-gray-300 hover:bg-gray-100 hover:text-blue-400 dark:hover:bg-gray-600 flex items-center rounded-md "
                      >
                        <label className="hover:cursor-pointer">Features</label>
                      </li>
                    </ul>
                  )}
                </div>
              ))}
          </div>
          <hr className="w-86 relative border-b-1"></hr>
        </div>
        <div className="flex flex-col">
          <div className="mx-4 my-2">
            <h3 className="px-6 py-2 font-inter font-medium tracking-wide text-gray-400 transition-colors duration-200 transform rounded-md dark:text-gray-300">
              OTHERS
            </h3>
            <div className="px-6 py-2 font-inter font-medium tracking-wide hover:cursor-pointer text-gray-700 dark:text-gray-400 hover:bg-gray-200 hover:text-blue-400 dark:hover:bg-gray-600 flex items-center rounded-md ">
              Promotions
            </div>
            <div className="px-6 py-2 font-inter font-medium tracking-wide hover:cursor-pointer text-gray-700 dark:text-gray-400 hover:bg-gray-200 hover:text-blue-400 dark:hover:bg-gray-600 flex items-center rounded-md ">
              Activity Logs
            </div>
          </div>
          <hr className="w-86 relative border-b-1"></hr>
        </div>
        <hr className="w-86 mt-auto mb-4 border-b-1"></hr>
        <div
          className="flex  relative py-2 items-center ml-6 space-x-4 cursor-pointer"
          onClick={logout}
        >
          <img
            className="w-10 h-10 bg-gray-100 rounded-full dark:bg-gray-300"
            alt=""
            src={require("../assets/images/logout.png")}
          />
          <div>
            <div className="w-116 h-23 font-medium text-base leading-normal text-gray-700 dark:text-gray-400">
              Log Out
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideNavBar;
