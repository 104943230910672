import { apiRequest } from "../../../utils/axios";
import { Payments } from "../types/payment";

type Response<T> = {
    data: T;
    message: string;
    status: boolean;
    pageInfo: Pageinfo
}

type Pageinfo = {
    totalCount: number,
    totalPages: number,
    currentPage: number,
    pageSize: number,
    hasNext: boolean,
    hasPrevious: boolean
}

export async function getAllPayments(pageSize: number = 1000, currentPage: number = 1, searchWord: string = ""): Promise<Response<Payments[]>> {
    return apiRequest('GET', `/api/payments?PageSize=${pageSize}&CurrentPage=${currentPage}&Search=${searchWord}`);
}

export async function updatePaymentDoc(data: FormData): Promise<Response<string>> {
    return apiRequest('PATCH', '/api/payments', data, "multipart/form-data");
}
