import { ReactElement, useState } from "react";
import { publicApiRequest } from "../../utils/axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginResponse } from "./dto/user-info.dto";
import { Navigate, useNavigate } from "react-router-dom";
import { Toast } from "../common/components/Toast";

function Login(): ReactElement {
  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: "",
  });

  const navigate = useNavigate();

  const handleLogin = async () => {
    // toast.promise();
    // const resp =  ;
    const resp = await toast
      .promise(publicApiRequest("POST", "/api/auth/login", loginInfo), {
        pending: "Logging in...",
        success: {
          render({ data }) {
            const respInfo = data as LoginResponse;

            if (respInfo.data.role.toLowerCase() == "admin") {
              localStorage.setItem("token", respInfo.data.token);
              localStorage.setItem("userInfo", JSON.stringify(respInfo.data));
              toast.success("Login Successful");
              navigate("/dashboard", { replace: true });
              return "Login Successful";
            } else {
              throw Error("Invalid user credentials");
            }
          },
        },
        error: {
          render({ data }) {
            return "Invalid email or password";
          },
        },
      })
      .catch((err) => {});
  };

  return (
    <div className="flex">
      <div className="h-screen w-screen bg-white flex flex-wrap content-around">
        <div className="w-1/2 bg-transparent rounded">
          <div className=" h-4/6 w-full flex flex-col items-center justify-center">
            <div>
              <img
                src={require("../../assets/images/chebelogo.png")}
                className="h-32 w-auto"
              />
            </div>
            <div>
              <h5 className="font-bold text-blue-800 text-2xl">
                Welcome Back!
              </h5>
            </div>
            <div className="mt-3">
              <p>Login to continue</p>
            </div>
            <form className="mt-5">
              <div className="mt-4 w-80">
                <input
                  type="text"
                  onChange={(e) =>
                    setLoginInfo({ ...loginInfo, email: e.target.value })
                  }
                  className="form-input bg-gray-100 p-2 w-full border-none"
                  placeholder="Email"
                />
              </div>
              <div className="mt-4 w-80">
                <input
                  type="password"
                  onChange={(e) =>
                    setLoginInfo({ ...loginInfo, password: e.target.value })
                  }
                  className="form-input bg-gray-100 p-2 w-full border-none"
                  placeholder="Password"
                />
              </div>
              <div className="mt-4 flex flex-col items-center justify-center">
                <input
                  type="button"
                  value={"Login"}
                  onClick={handleLogin}
                  className="bg-orange-600 pl-5 pr-5 pt-3 pb-3 rounded-lg cursor-pointer text-white"
                />
              </div>
            </form>
          </div>
          <div className="bg-white h-2/6 bg-[url('./assets/images/abuja-tower.png')] bg-left bg-no-repeat opacity-60"></div>
        </div>
        <div className="w-1/2 h-full bg-transparent bg-[url('assets/images/login-background.png')] bg-cover bg-center">
          <div className="h-1/2 w-3/4 bg-transparent"></div>
          <div className="h-1/2 w-3/4 bg-[url('assets/images/city-gate.png')] bg-cover bg-center float-right opacity-30"></div>
        </div>
      </div>

      {/* Same as */}
      <Toast />
    </div>
  );
}

export default Login;
