import { Dialog, Transition } from "@headlessui/react";
import { Button } from "flowbite-react";
import {
  ChangeEvent,
  Fragment,
  ReactElement,
  useEffect,
  useState,
} from "react";
import { CreateProductFeature } from "../types/product";
import { toast } from "react-toastify";
import { addProductFeature } from "../services/api";
import { useLocation } from "react-router-dom";
import { CategoryData } from "../types/policy";

function ProductFilter(): ReactElement {
  const [openModal, setOpenModal] = useState(false);
  const [newFeature, setNewFeature] = useState<CreateProductFeature>();
  const [categoryName, setCategoryName] = useState<string | undefined>("");
  const location = useLocation();
  const categoryId = location.pathname.split("/").pop();

  useEffect(() => {
    const categories = JSON.parse(
      localStorage.getItem("categories") ?? ""
    ) as CategoryData[];
    const cateId = Number(categoryId);
    const category = categories.find((x) => x.id == cateId);
    setCategoryName(category?.name);
  });

  function handleFormChange(e: ChangeEvent<HTMLInputElement>): void {
    var update: any = {};
    update[e.target.name] = e.target.value;
    if (e.target.value.toLowerCase() === "on") {
      update[e.target.name] = true;
    }
    if (e.target.value.toLowerCase() === "off") {
      update[e.target.name] = false;
    }
    const copyNewFeature = { ...newFeature, ...update };
    setNewFeature(copyNewFeature);
  }

  async function saveFeature() {
    console.log("new Feature: ", newFeature);
    if (
      newFeature?.featureName == undefined ||
      newFeature?.featureName?.trim() == "" ||
      newFeature?.description == undefined ||
      newFeature?.description?.trim() == ""
    ) {
      if (
        newFeature?.featureName == undefined ||
        newFeature?.featureName?.trim() == ""
      ) {
        toast.warning("Feature name is required");
      } else {
        toast.warning("Description is required");
      }
    } else {
      const id = Number(categoryId);
      await toast
        .promise(addProductFeature(id, newFeature), {
          pending: "Creating new Feature",
          success: "New Feature Created Successfully",
        })
        .catch();
    }
  }

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="relative flex flex-row justify-between mt-24 bg-white w-[100%] h-[128px] rounded-xl">
          <p className="p-4 w-96 h-6 font-inter font-semibold text-lg leading-tight text-blue-700">
            {categoryName} Insurance
          </p>
          <div className="flex flex-row mt-16 mx-4">
            <div className="mt-2">
              <span className="w-116 h-23 font-medium text-base leading-normal text-gray-400">
                Filter:{" "}
              </span>
              <button
                type="button"
                className="relative mx-2 text-orange-700 bg-orange-100 border border-orange-700 focus:outline-none hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-36 h-7"
              >
                <span className="inline-block mr-3">All</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="inline-block w-3 h-3 fill-current text-orange-700 absolute top-1/2 right-3 transform -translate-y-1/2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </button>
              <button
                type="button"
                className="relative mx-2 text-orange-700 bg-orange-100 border border-orange-700 focus:outline-none hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-20 h-7"
              >
                <span className="inline-block mr-3">All</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className="inline-block w-3 h-3 fill-current text-orange-700 absolute top-1/2 right-3 transform -translate-y-1/2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                  />
                </svg>
              </button>
              <button
                onClick={() => setOpenModal(true)}
                type="button"
                className="relative mx-2 pl-2 text-blue-700 bg-gray-300 border border-blue-700 focus:outline-none hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-20 h-7"
              >
                <span className="inline-block mr-3">Create</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <Transition appear show={openModal} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setOpenModal(false)}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-orange-700"
                  >
                    Create Feature
                    <br />
                  </Dialog.Title>
                  <div className="mt-10">
                    <form>
                      <div className="mb-6">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Feature Name <span className="text-red-700">*</span>
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Feature Name"
                          autoComplete={"off"}
                          required
                          onChange={(e) => handleFormChange(e)}
                          name="featureName"
                        />
                      </div>
                      <div className="mb-6">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Feature Description{" "}
                          <span className="text-red-700">*</span>
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Feature Description"
                          autoComplete={"off"}
                          required
                          onChange={(e) => handleFormChange(e)}
                          name="description"
                        />
                      </div>
                      <div className="flex content-center">
                        <button
                          onClick={saveFeature}
                          type="button"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        >
                          Save Feature
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}

export default ProductFilter;
