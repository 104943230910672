import { ChangeEvent, Fragment, ReactElement, useState } from "react";
import PartnerSearchBar from "../components/partnerSearchBar";
import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import { getAllPartners, updatePartners } from "../services/api";
import { Partner } from "../types/partners";
import { Pagination } from "flowbite-react";
import { toast } from "react-toastify";

function PartnerManagement(): ReactElement {
  const [open, setOpen] = React.useState(false);
  const [selectedPartner, setSelectedPartnerTo] = useState<Partner | undefined>(
    undefined
  );

  const [partners, setPartners] = useState<Partner[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [partnerCount, setPartnerCount] = useState(0);

  const handleItemClick = (partner: Partner) => {
    setSelectedPartnerTo(partner);
    setOpen(true);
  };

  const onPageChange = (page: number) => setCurrentPage(page);

  async function searchPartners(searchWord: string) {
    const { data } = await getAllPartners(searchWord);
    setPartners(data);
  }

  function updateSelectedPartner(e: ChangeEvent<HTMLInputElement>) {
    let update: any = {};
    if (e.target.value == "on") {
      update[e.target.name] = e.target.checked;
    } else {
      update[e.target.name] = e.target.value;
    }
    const updatedPartner = { ...selectedPartner, ...update };
    setSelectedPartnerTo(updatedPartner);
  }

  function handleSavePartner() {
    var formData = new FormData();
    formData.append("registrationNo", selectedPartner?.registrationNo ?? "");
    formData.append("tinNo", selectedPartner?.tinNo ?? "");
    formData.append("officialName", selectedPartner?.officialName ?? "");
    formData.append("shortName", selectedPartner?.shortName ?? "");
    formData.append("address", selectedPartner?.address ?? "");
    formData.append("phoneNo", selectedPartner?.phoneNo ?? "");
    formData.append("emailAddress", selectedPartner?.emailAddress ?? "");
    formData.append(
      "isActive",
      selectedPartner?.isActive == true ? "true" : "false"
    );

    toast
      .promise(updatePartners(formData, selectedPartner?.partnerId), {
        pending: "Updating Partner",
        success: {
          render({ data }) {
            getPartners.refetch();
            return "Partner Updated Successfully";
          },
        },
      })
      .catch(() => {
        toast.error("Error updating Partner");
      });
  }

  const getPartners = useQuery(
    ["partners"],
    async () => {
      const { data } = await getAllPartners();
      setPartnerCount(data.length);
      setPartners(data);
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  return (
    <>
      <div className="flex-1 bg-gray-100 px-4">
        <PartnerSearchBar
          total={partnerCount}
          searchPartners={searchPartners}
        />
        <div className="mx-auto mt-4 flex-1 w-full h-auto relative items-center justify-center space-x-4">
          <div className="relative overflow-x-auto rounded-xl">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs border-b text-blue-700 bg-white dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-4">
                    RC Number
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Phone Number
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Official Name
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Short Name
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Logo
                  </th>
                  <th scope="col" className="px-6 py-4"></th>
                </tr>
              </thead>
              <tbody>
                {partners?.map((partner) => (
                  <tr
                    onClick={() => handleItemClick(partner)}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-2 font-medium text-sm text-gray-500 whitespace-nowrap dark:text-white"
                    >
                      {partner?.registrationNo}
                    </th>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {partner.phoneNo}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500 capitalize">
                      {partner.officialName}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500 capitalize">
                      {partner.shortName}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      <img
                        src={partner.logo}
                        alt="partner_logo"
                        height={50}
                        width={50}
                        className="rounded-full  border"
                      />
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="px-6 h-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                        />
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {/* <div className="flex overflow-x-auto sm:justify-center">
              <Pagination
                currentPage={1}
                totalPages={15}
                onPageChange={onPageChange}
              />
            </div> */}
          </div>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10 " onClose={setOpen}>
          <div className="fixed inset-0 overflow-hidden shadow-2xl">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-[651px]">
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6">
                        <Dialog.Title className="text-base font-semibold leading-6 text-gray-900  flex space-x-5">
                          <img
                            src={selectedPartner?.logo}
                            alt="partner_logo"
                            height={100}
                            width={100}
                            className="rounded  border"
                          />
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-5">
                        <div>
                          <label className="text-sm">RC Number</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPartner?.registrationNo}
                            name="registrationNo"
                            onChange={(e) => updateSelectedPartner(e)}
                          />
                        </div>
                        <div>
                          <label className="text-sm">TIN Number</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPartner?.tinNo}
                            name="tinNo"
                            onChange={(e) => updateSelectedPartner(e)}
                          />
                        </div>
                        <div>
                          <label className="text-sm">Official Name</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPartner?.officialName}
                            name="officialName"
                            onChange={(e) => updateSelectedPartner(e)}
                          />
                        </div>
                        <div>
                          <label className="text-sm">Short Name</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPartner?.shortName}
                            name="shortName"
                            onChange={(e) => updateSelectedPartner(e)}
                          />
                        </div>
                        {/* <div>
                          <label className="text-sm">Contact person</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPartner?.contactPerson}
                            disabled
                          />
                        </div> */}
                        <div>
                          <label className="text-sm">
                            Contact person email
                          </label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPartner?.emailAddress}
                            name="emailAddress"
                            onChange={(e) => updateSelectedPartner(e)}
                          />
                        </div>
                        <div>
                          <label className="text-sm">
                            Contact Phone number
                          </label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPartner?.phoneNo}
                            name="phoneNo"
                            onChange={(e) => updateSelectedPartner(e)}
                          />
                        </div>

                        <div>
                          <label className="text-sm">Address</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-3 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            value={selectedPartner?.address}
                            name="address"
                            onChange={(e) => updateSelectedPartner(e)}
                          />
                        </div>
                        <div>
                          <label className="text-sm">Is Active</label>
                          <br />
                          <br />
                          <input
                            className="bg-gray-50 rounded-lg w-[10%] px-5 text-gray-700 leading-tight focus:outline-none focus:shadow-outline float-left"
                            type="checkbox"
                            checked={selectedPartner?.isActive}
                            name="isActive"
                            onChange={(e) => updateSelectedPartner(e)}
                          />
                        </div>
                      </div>
                      <div className="mx-[170px]">
                        <button
                          type="button"
                          className="inline-flex justify-center rounded-md border hover:cursor-pointer border-transparent bg-green-500 bg-opacity-20 px-6 py-2 text-sm font-medium text-green-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                          onClick={() => handleSavePartner()}
                        >
                          Save Changes
                        </button>
                        <span
                          onClick={() => setOpen(false)}
                          className="mx-4 text-red-600 px-4 underline hover:cursor-pointer"
                        >
                          Cancel
                        </span>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default PartnerManagement;
