import {
  ChangeEvent,
  Fragment,
  ReactElement,
  useEffect,
  useState,
} from "react";
import ProductFilter from "../components/productFilter";
import { useParams } from "react-router-dom";
import {
  CheckIcon,
  PencilSquareIcon,
  PlusSmallIcon,
  TrashIcon,
} from "@heroicons/react/24/solid";

import React from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { getAllFeatures, updateProductFeature } from "../services/api";
// eslint-disable-next-line
import {
  CreateProductFeature,
  Policy,
  ProductFeature,
  ProductFeatureMap,
} from "../types/product";
import { EditProductFeatureForm } from "../components/editProductFeatureForm";
import { Confirmation } from "../../common/components/Confirmation";
import { Button } from "flowbite-react";
import { Dialog, Transition } from "@headlessui/react";
import { Toast } from "../../common/components/Toast";
import { toast } from "react-toastify";

function InsuranceFeatures(): ReactElement {
  const { categoryId } = useParams();
  const [rows, setRows] = useState([] as any[]);
  const [name, setName] = useState("");
  const [showField, setShowField] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedProductFeature, setSelectedProductFeature] = useState<
    CreateProductFeature | undefined
  >();
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [productFeatureMaps, setProductFeatureMapsTo] = useState<
    ProductFeatureMap[]
  >([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {}, [categoryId]);

  function handleFormChange(e: ChangeEvent<HTMLInputElement>): void {
    var update: any = {};
    update[e.target.name] = e.target.value;
    if (e.target.value.toLowerCase() === "on") {
      update[e.target.name] = true;
    }
    if (e.target.value.toLowerCase() === "off") {
      update[e.target.name] = false;
    }
    const copyNewFeature = { ...selectedProductFeature, ...update };
    setSelectedProductFeature(copyNewFeature);
  }

  const getFeatures = useQuery(
    ["features"],
    async () => {
      const { data } = await getAllFeatures(categoryId);
      return data;
    },
    {
      refetchOnWindowFocus: false,
    }
  );

  // const getPolicies = useQuery(["policies", productId], async () => {
  //   const { data } = await getAllPolicies(productId!);
  //   return data;
  // });

  // const getFeatureMaps = async () => {
  //   const { data } = await getAllFeatureMaps();
  //   setProductFeatureMapsTo(data);
  // };

  // useEffect(() => {
  //   getFeatureMaps();
  // }, []);

  // const updateFeature = useMutation(
  //   (feature: ProductFeature) => updateProductFeature(feature),
  //   {
  //     async onSuccess() {
  //       setShowModal(false);
  //       setSelectedProductFeature(undefined);
  //       await getFeatures.refetch();
  //     },
  //   }
  // );

  // const deleteFeature = useMutation((id: number) => deleteProductFeature(id), {
  //   async onSuccess() {
  //     setShowConfirmation(false);
  //     setSelectedProductFeature(undefined);
  //     await getFeatures.refetch();
  //   },
  // });

  // const saveRow = async () => {
  //   if (!name.length) return;
  //   await addProductFeature({
  //     name: name,
  //     productId: productId!,
  //   });
  //   await getFeatures.refetch();
  //   setShowField(false);
  //   setRows([]);
  // };

  const addNewRow = () => {
    setRows([...rows, ""]);
    setShowField(true);
  };

  const handleRowChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    setName(event.target.value);
  };

  const editFeature = (feature: CreateProductFeature) => {
    setSelectedProductFeature(feature);
    setIsOpen(true);
  };

  const removeProductFeature = (feature: CreateProductFeature) => {
    setSelectedProductFeature(feature);
    setShowConfirmation(true);
  };

  async function updateFeature() {
    await toast
      .promise(
        updateProductFeature(
          selectedProductFeature?.id,
          selectedProductFeature
        ),
        {
          pending: "Updating product Feature",
          success: "Feature Updated Successfully",
        }
      )
      .catch();

    await getFeatures.refetch();
  }

  function handleUpdateMap(policyId: number, featureId: number): void {
    // const mapIndex = productFeatureMaps.findIndex(
    //   (x) => x.policyId === policyId && x.productFeatureId === featureId
    // );
    // const copyMaps = [...productFeatureMaps];
    // const map = copyMaps[mapIndex]; // productFeatureMaps.find(x => x.policyId === policyId && x.productFeatureId === featureId)
    // if (!map?.id) {
    //   addNewProductFeatureMap({
    //     policyId: policyId,
    //     productFeatureId: featureId,
    //     response: map.response,
    //   }).then(() => {
    //     getFeatureMaps();
    //   });
    // } else {
    //   updateProductFeatureMap({ id: map!.id!, response: map.response }).then(
    //     () => {
    //       getFeatureMaps();
    //     }
    //   );
    // }
  }

  function handleMapResponseChange(
    e: React.ChangeEvent<HTMLInputElement>,
    policyId: number,
    featureId: number
  ): void {
    const mapIndex = productFeatureMaps.findIndex(
      (x) => x.policyId === policyId && x.productFeatureId === featureId
    );
    let copyMaps = [...productFeatureMaps];
    if (mapIndex === -1) {
      copyMaps.push({
        policyId: policyId,
        productFeatureId: featureId,
        response: e.target.value,
      });
    } else {
      copyMaps[mapIndex].response = e.target.value;
    }
    setProductFeatureMapsTo(copyMaps);

    // throw new Error("Function not implemented.");
  }

  return (
    <>
      <div className="flex-1 bg-gray-100 px-4">
        <ProductFilter />
        <div className="mx-auto mt-4 flex-1 w-[100%] h-[auto] relative items-center justify-center space-x-4">
          <div className="relative overflow-x-auto rounded-xl">
            <table className="w-full text-sm text-left bg-white text-gray-500 dark:text-gray-400">
              <thead className="text-xs border-b bg-white dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-4"></th>
                  <th scope="col" className="px-6 py-4 text-blue-700">
                    Features
                  </th>
                  {/* {getPolicies.status === "success" &&
                    getPolicies.data?.map((policy, index: number) => (
                      <>
                        <th
                          scope="col"
                          className="px-6 py-4 text-orange-700 text-center"
                        >
                          <p>{policy.name}</p>

                          <p>{policy.partnerName}</p>
                          <p>{policy.policyType}</p>
                        </th>
                      </>
                    ))} */}
                  <th scope="col" className="relative px-6 py-2 text-gray-400">
                    <div className="flex items-center">
                      <PlusSmallIcon className={"h-4 w-4 text-gray-500"} />
                      <span className="inline-block">New</span>
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {getFeatures.status === "success" &&
                  getFeatures.data?.map(
                    (feature: CreateProductFeature, index: number) => (
                      <tr
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                        key={feature.id}
                      >
                        <td>
                          <div className={"flex flex-row space-x-2"}>
                            <PencilSquareIcon
                              className="text-black-500 h-5 w-5 ml-8 cursor-pointer"
                              onClick={() => editFeature(feature)}
                            />
                            <TrashIcon
                              className="text-red-500 h-5 w-5 cursor-pointer"
                              onClick={() => removeProductFeature(feature)}
                            />
                          </div>
                        </td>
                        <th
                          scope="row"
                          className="px-6 py-2 font-medium text-sm text-gray-500 whitespace-nowrap dark:text-white"
                        >
                          {feature.featureName}
                        </th>

                        {/* {getPolicies.status === 'success' && getPolicies.data?.map((policy, index: number) => (
                                    <>
                                        <td className="px-6 py-2 text-sm font-medium text-gray-500">
                                            <div className="flex items-center space-x-2">
                                                <label>{[...productFeatureMaps].find(x => x.policyId === policy.id && x.productFeatureId === feature.id)?.response || "-"}</label>
                                                <input className="border p-2 bg-gray-100 rounded w-fit" onChange={(e) => handleMapResponseChange(e, policy.id, feature.id) } />
                                                <CheckIcon className="w-5 h-5 border rounder p-1" onClick={() => handleUpdateMap(policy.id, feature.id) } />
                                            </div>
                                        </td>
                                    </>
                                ))} */}
                      </tr>
                    )
                  )}
                {rows.map((row, index) => (
                  <tr className="border-b">
                    {showField && (
                      <>
                        <button
                          className="px-8 py-2 font-medium text-xs text-blue-600 dark:text-blue-500 hover:underline"
                          // onClick={}
                        >
                          Save
                        </button>
                        <td className="px-6 py-2 text-sm font-medium text-gray-500">
                          <input
                            placeholder="Enter feature name"
                            className="font-medium text-sm text-gray-500 whitespace-nowrap dark:text-white"
                            type="text"
                            value={row.name}
                            onChange={(event) => handleRowChange(event, index)}
                          />
                        </td>
                      </>
                    )}
                  </tr>
                ))}
                <tr>
                  <td className="px-6 py-2 font-medium text-sm text-gray-500 whitespace-nowrap dark:text-white">
                    <div className="flex items-center" onClick={addNewRow}>
                      <PlusSmallIcon className={"h-4 w-4 text-gray-500"} />
                      <span className="inline-block">New</span>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        {showConfirmation && (
          <Confirmation
            isLoading={loading}
            onAccept={() => console.log("accepted")}
            onClose={() => {
              setShowConfirmation(false);
              setSelectedProductFeature(undefined);
            }}
            isOpen={showConfirmation}
            title={"Warning"}
          >
            {`Are you sure you want to delete ${selectedProductFeature?.featureName} feature?`}
          </Confirmation>
        )}
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-10"
          onClose={() => setIsOpen(false)}
        >
          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-orange-700"
                  >
                    Update Feature
                    <br />
                  </Dialog.Title>
                  <div className="mt-10">
                    <form>
                      <div className="mb-6">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Feature Name <span className="text-red-700">*</span>
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Feature Name"
                          autoComplete={"off"}
                          required
                          onChange={(e) => handleFormChange(e)}
                          name="featureName"
                          value={selectedProductFeature?.featureName}
                        />
                      </div>
                      <div className="mb-6">
                        <label
                          htmlFor="email"
                          className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                        >
                          Feature Description{" "}
                          <span className="text-red-700">*</span>
                        </label>
                        <input
                          type="text"
                          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                          placeholder="Feature Description"
                          autoComplete={"off"}
                          required
                          onChange={(e) => handleFormChange(e)}
                          name="description"
                          value={selectedProductFeature?.description}
                        />
                      </div>
                      <div className="flex content-center">
                        <button
                          onClick={updateFeature}
                          type="button"
                          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 me-2 mb-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                        >
                          Save Changes
                        </button>
                      </div>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <Toast />
    </>
  );
}

export default InsuranceFeatures;
