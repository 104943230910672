import { Policy, PolicyFeatures } from "./product";

export interface PolicyChildComponentProps {
    total: number | undefined;
    getPolicies: () => void;
    // openEditModal: boolean;
    // selectedPolicy?: Policy;
    // setCloseModal: () => void;
    // setPolicy: (policy: Policy) => void;
}

export interface PolicyTypeDto {
    Id: number;
    value: string;
    type: PolicyTypeEnum;
    label: string
}

export enum PolicyTypeEnum {
    Motor = 'Motor',
    Life = 'Life'
}

export interface CategoryData {
    id: number
    name: string;
}