import { ReactElement } from "react";

function AdminDashboard(): ReactElement {
  return (
    <div className="flex">
      <div className="flex-1 h-screen bg-gray-100 pl-4">
        <div className="m-12 mt-32 relative flex  items-center space-x-4">
          <img
            className="w-14 h-14 bg-gray-300 rounded-full dark:bg-gray-600"
            alt=""
          />
          <div>
            <span className="w-64 h-6 font-inter font-semibold text-lg leading-tight text-blue-700">
              Welcome Back,{" "}
            </span>
            <span className="w-64 h-6 font-inter font-semibold text-lg leading-tight text-gray-600"></span>
            <div className="w-116 h-23 font-medium text-base leading-normal text-gray-600">
              Administrator
            </div>
          </div>
          <div className="flex inline flex-wrap pl-[765px]">
            {/* <button
              type="button"
              className="relative mx-2 text-blue-700 bg-gray-300 border border-blue-700 focus:outline-none hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-20 h-7"
            >
              <span className="inline-block mr-3">New</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className=" inline-block w-4 h-4 fill-current text-blue-700 absolute top-1/2 right-3 transform -translate-y-1/2"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 6v12m6-6H6"
                />
              </svg>
            </button> */}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-wrap ml-4 justify-items-start">
            <div className="flex">
              <div className="w-96 h-52 bg-white mx-3 rounded-lg "></div>
              <div className="w-96 h-52 bg-white mx-3 rounded-lg "></div>
              <div className="w-96 h-52 bg-white mx-3 rounded-lg "></div>
            </div>
          </div>
          <div className="flex flex-wrap ml-4 justify-items-start">
            <div className="w-[792px] mt-8 h-96 bg-white mx-3 rounded-lg"></div>
            <div className="w-96 h-96 mt-8 bg-white mx-3 rounded-lg "></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdminDashboard;
