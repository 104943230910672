import { Dialog, Transition } from "@headlessui/react";
import { ReactElement, useState, Fragment, ChangeEvent } from "react";
import {
  Category,
  CreateNewPolicy,
  Feature,
  PolicyFeatures,
  coverRequest,
} from "../types/product";
import {
  addNewPolicy,
  getAllCategories,
  getAllPartners,
  getAllPolicies,
  getAllPolicyClasses,
  getAllPolicyCovers,
  getPolicyTypes,
} from "../services/api";
import { useQuery } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";
import { PolicyChildComponentProps, PolicyTypeDto } from "../types/policy";
import { toast } from "react-toastify";

type DescriptionData = {
  id: number;
  description: string;
};

const PolicySearchBar: React.FC<PolicyChildComponentProps> = (props) => {
  let [isOpen, setIsOpen] = useState(false);
  let [features, setFeatures] = useState<Feature[]>([]);
  let [selFeatures, setSelectedFeature] = useState<Feature[]>([]);
  let [policyTypes, setPolicyTypes] = useState<PolicyTypeDto[]>([]);
  let [categoryName, setCategoryName] = useState<string | undefined>("");
  const [policyFeatures, setPolicyFeatures] = useState<PolicyFeatures[]>([]);
  const [descriptions, setDescriptions] = useState<DescriptionData[]>([]);

  const location = useLocation();
  const categoryId = location.pathname.split("/").pop();

  let [newPolicy, setNewPolicyTo] = useState<CreateNewPolicy>({
    categoryId: categoryId,
  });

  const [policyPriceType, setPolicyPriceType] = useState("");

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setNewPolicyTo({
      categoryId: categoryId,
    });
    setIsOpen(true);
    setSelectedFeature([]);
  }

  function addCommas(num: string) {
    num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  function removeNonNumeric(num: string) {
    return num.toString().replace(/[^0-9]/g, "");
  }

  const getPartners = useQuery(
    ["partners"],
    async () => {
      const { data } = await getAllPartners();
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  const getCategories = useQuery(
    ["categories"],
    async () => {
      const { data } = await getAllCategories();

      setCategoryName(
        data.find((x) => x.id.toString() == categoryId)?.categoryName
      );

      setFeatures(
        data.find((x) => x.id.toString() == categoryId)?.features ?? []
      );
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  async function handleSavePolicy(): Promise<void> {
    newPolicy.coverRequest = selFeatures.map((x) => {
      const descr = descriptions.find((y) => y.id == x.id);
      const data: coverRequest = {
        description: descr?.description ?? "",
        isActive: true,
        featureName: x.featureName,
      };

      return data;
    });

    let err = false;
    if (newPolicy.coverRequest.length == 0) {
      toast.warning("No cover feature selected");
      err = true;
    } else if (newPolicy.policyName?.trim() == "") {
      toast.warning("Policy Name is required");
      err = true;
    } else if (newPolicy.categoryId == 0) {
      toast.warning("Policy Category is required");
      err = true;
    } else if (newPolicy.partnerId == 0) {
      toast.warning("Policy Partner is required");
      err = true;
    } else if (newPolicy.policyPriceType == "") {
      toast.warning("Policy Price Type is required");
      err = true;
    } else if (newPolicy.policyPrice == 0) {
      toast.warning("Policy Price is required");
      err = true;
    }

    if (!err) {
      if (
        newPolicy.policyPriceType == "Percentage" &&
        newPolicy.policyPrice != undefined
      ) {
        newPolicy.policyPrice =
          parseFloat(newPolicy.policyPrice.toString()) / 100;
      }

      const resp = await toast
        .promise(addNewPolicy({ ...newPolicy }), {
          pending: "Creating new Policy",
          success: "New Policy Created Successfully",
          error: "Error when creating policy",
        })
        .catch(() => {
          toast.error("Error when creating Policy");
        });

      closeModal();
      console.log("getting policies");
      props.getPolicies();
    }
  }

  function handleCheckChange(
    e: ChangeEvent<HTMLInputElement>,
    featureId: number
  ): void {
    if (e.target.checked) {
      const feature = features.find((x) => x.id == featureId);
      if (feature != undefined) {
        selFeatures.push(feature);
        setSelectedFeature(selFeatures);
      }
    } else {
      const features = selFeatures.filter((x) => x.id != featureId);
      setSelectedFeature(features);
    }
  }

  function handleDescriptionChange(
    e: ChangeEvent<HTMLInputElement>,
    featureId: number
  ) {
    const descr = descriptions.find((x) => x.id == featureId);
    if (descr == undefined) {
      const descr: DescriptionData = {
        id: featureId,
        description: e.target.value,
      };
      descriptions.push(descr);
    } else {
      descr.description = e.target.value;
    }

    setDescriptions(descriptions);
  }

  function handleCategoryChange(e: ChangeEvent<HTMLSelectElement>): void {
    const copyNewPolicy = { ...newPolicy, policyType: e.target.value };
    setNewPolicyTo(copyNewPolicy);
  }

  function handlePartnerChange(e: ChangeEvent<HTMLSelectElement>): void {
    const copyNewPolicy = { ...newPolicy, partnerId: e.target.value };
    setNewPolicyTo(copyNewPolicy);
  }

  function handlePriceTypeChange(e: ChangeEvent<HTMLSelectElement>): void {
    const copyNewPolicy = { ...newPolicy, policyPriceType: e.target.value };
    setPolicyPriceType(copyNewPolicy.policyPriceType);
    setNewPolicyTo(copyNewPolicy);
  }

  async function getPolicyCovers(policyId: number) {
    const resp = await getAllPolicyCovers(policyId);
    setPolicyFeatures(resp.data);
  }

  function handleFormChange(e: ChangeEvent<HTMLInputElement>): void {
    var update: any = {};
    // e.target.name;

    update[e.target.name] = e.target.value;
    if (e.target.value.toLowerCase() === "on") {
      update[e.target.name] = true;
    }
    if (e.target.value.toLowerCase() === "off") {
      update[e.target.name] = false;
    }
    const copyNewPolicy = { ...newPolicy, ...update };
    setNewPolicyTo(copyNewPolicy);

    if (e.target.name == "policyPrice") {
      e.target.value = removeNonNumeric(e.target.value);

      if (parseFloat(e.target.value) > 0 && policyPriceType == "Percentage") {
        // console.log("Price value: ", e.target.value);
        if (
          policyPriceType == "Percentage" &&
          parseFloat(e.target.value) > 100
        ) {
          update[e.target.name] = 0;
        } else {
          console.log(parseFloat(e.target.value));
          update[e.target.name] = parseFloat(e.target.value);
        }

        if (e.target.value.toLowerCase() === "on") {
          update[e.target.name] = true;
        }
        if (e.target.value.toLowerCase() === "off") {
          update[e.target.name] = false;
        }
        const copyNewPolicy = { ...newPolicy, ...update };
        setNewPolicyTo(copyNewPolicy);
      }

      let value =
        parseFloat(e.target.value) < 0
          ? "0"
          : parseFloat(e.target.value).toString();

      newPolicy["policyPrice"] = value;
      e.target.value = value;
      update[e.target.name] = value;
    }
  }

  return (
    <>
      <div className="flex items-center justify-center">
        <div className="relative flex flex-row justify-between mt-24 bg-white w-full h-[128px] rounded-xl">
          <p className="p-4 w-96 h-6 font-inter font-semibold text-lg leading-tight text-blue-700">
            Total Number of Policies: {props.total}
          </p>
          <div className="flex flex-row mt-16 mr-4">
            <div className="px-4 bg-white dark:bg-gray-900">
              <label className="sr-only">Search</label>
              <div className="mt-1">
                <input
                  id="table-search"
                  className="w-80 h-8 block p-2 pl-10 font-medium text-base leading-normal bg-white text-gray-400 border border-blue-700 rounded-3xl w-80 bg-gray-50 focus:ring-blue-700 focus:border-blue-700 dark:bg-gray-700 dark:border-blue-700 dark:placeholder-blue-700 dark:text-white dark:focus:ring-blue-700 dark:focus:border-blue-700"
                  placeholder="Search "
                ></input>
              </div>
            </div>
            <div className="mt-2">
              <button
                onClick={openModal}
                type="button"
                className="relative mx-2 text-blue-700 bg-gray-300 border border-blue-700 focus:outline-none hover:bg-gray-300 focus:ring-4 focus:ring-gray-300 font-medium rounded-full text-sm dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700 w-20 h-7"
              >
                <span className="inline-block mr-3">Create</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  className=" inline-block w-4 h-4 fill-current text-blue-700 absolute top-1/2 right-3 transform -translate-y-1/2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M12 6v12m6-6H6"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="min-w-[780px]  transform overflow-hidden rounded-lg bg-white p-8 text-left align-middle shadow-sm transition-all">
                  <Dialog.Title
                    as="h3"
                    className="pb-12 w-96 h-6 font-inter font-semibold text-lg leading-tight text-orange-700"
                  >
                    New Policy - {categoryName} Insurance
                  </Dialog.Title>
                  <div className="w-full min-h-[500px]">
                    <form className="w-full">
                      <div className="grid grid-cols-3 gap-3">
                        <div>
                          <label className="text-gray-700 text-sm">Name</label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="companyName"
                            type="text"
                            placeholder="New Policy Name"
                            value={newPolicy["policyName"]}
                            name="policyName"
                            onChange={(e) => handleFormChange(e)}
                          />
                        </div>
                        <div>
                          <label className="text-gray-700 text-sm">
                            Category
                          </label>
                          <br />
                          <select
                            onChange={(e) => handleCategoryChange(e)}
                            className="bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value={0} disabled selected>
                              Select Policy Category
                            </option>
                            {getPolicyTypes()
                              .filter(
                                (x) =>
                                  x.type.toLocaleLowerCase() ==
                                  categoryName?.toLowerCase()
                              )
                              .map((cate) => (
                                <option value={cate.Id}>{cate.label}</option>
                              ))}
                          </select>
                        </div>
                        <div>
                          <label className="text-gray-700 text-sm">
                            Partner
                          </label>
                          <br />
                          <select
                            onChange={(e) => handlePartnerChange(e)}
                            className="bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value={0} disabled selected>
                              Select Policy Partner
                            </option>
                            {getPartners.data?.map((part) => (
                              <option value={part.id}>
                                {part.companyName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="grid grid-cols-3 gap-3 mt-3">
                        <div>
                          <label className="text-gray-700 text-sm">
                            Price Type
                          </label>
                          <br />
                          <select
                            onChange={(e) => handlePriceTypeChange(e)}
                            className="bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                          >
                            <option value={0} disabled selected>
                              Select Policy Price Type
                            </option>
                            {["Flat", "Percentage"].map((cate) => (
                              <option value={cate}>{cate}</option>
                            ))}
                          </select>
                        </div>
                        <div>
                          <label className="text-gray-700 text-sm">
                            Price{" "}
                            <span className="bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                              {policyPriceType == "Percentage"
                                ? " Price <= 100 "
                                : ""}
                            </span>
                          </label>
                          <input
                            className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            id="policyPrice"
                            type="text"
                            min={0}
                            placeholder="Policy Price"
                            value={newPolicy["policyPrice"]}
                            name="policyPrice"
                            onChange={(e) => handleFormChange(e)}
                          />
                        </div>
                      </div>
                      <div className="border-[#ccdcf7] border rounded-md min-h-full w-full mt-5 p-2">
                        <table className="w-full">
                          <thead>
                            <tr className="w-full text-blue-900 font-bold border-b">
                              <td className="w-[10%] text-center border-r p-1">
                                Select
                              </td>
                              <td className="w-[40%] border-r p-1">Features</td>
                              <td className="w-[50%] border-l p-1">
                                Description
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            {features.map((feature) => (
                              <tr className="text-[#767676] border-b text-sm">
                                <td className="grid place-items-center p-2">
                                  <input
                                    onChange={(e) => {
                                      handleCheckChange(e, feature.id);
                                    }}
                                    type="checkbox"
                                    className="checked:bg-orange-500 text-orange-500"
                                    value={feature.id}
                                  />
                                </td>
                                <td className="border-l m-2 p-1">
                                  {feature.featureName}
                                </td>
                                <td className="border-l p-1">
                                  <input
                                    className="appearance-none bg-gray-50 rounded-lg w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                    type="text"
                                    placeholder="Feature Description"
                                    onChange={(e) =>
                                      handleDescriptionChange(e, feature.id)
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </form>
                  </div>
                  <div className="grid grid-cols-2 mt-5">
                    <div>
                      <button
                        type="button"
                        className="inline-flex rounded-md float-right border border-transparent bg-blue-900 px-6 py-2 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus-visible:ring-2  focus-visible:ring-offset-2"
                        onClick={() => handleSavePolicy()}
                      >
                        Save
                      </button>
                    </div>
                    <div>
                      <span
                        onClick={closeModal}
                        className="mx-4 text-red-600 px-4 underline cursor-pointer"
                      >
                        Cancel
                      </span>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {/* <Toast /> */}
    </>
  );
};

export default PolicySearchBar;
