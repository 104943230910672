import { Dialog, Transition } from "@headlessui/react";
import { useQuery } from "@tanstack/react-query";
import {
  ChangeEvent,
  Fragment,
  ReactElement,
  useEffect,
  useState,
} from "react";
import {
  addNewPolicy,
  getAllPartners,
  getAllPolicies,
  getAllPolicyClasses,
  getAllPolicyCovers,
  getPolicyTypes,
  updatePolicy,
} from "../services/api";
import { CreateNewPolicy, Policy, PolicyFeatures } from "../types/product";
import PolicySearchBar from "../components/policySearchBar";
import { useLocation } from "react-router-dom";
import { NumericFormat } from "react-number-format";
import { toast } from "react-toastify";
import { Toast } from "../../common/components/Toast";
import { Pagination } from "flowbite-react";
import { CategoryData } from "../types/policy";

function InsurancePolicies(): ReactElement {
  let [isOpen, setIsOpen] = useState(false);
  const [noOfPolicies, setPolicyCount] = useState<number | undefined>(0);
  const [policyPriceType, setPolicyPriceType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const location = useLocation();
  const categoryId = location.pathname.split("/").pop();
  let [newPolicy, setNewPolicyTo] = useState<CreateNewPolicy>({
    categoryId,
  });
  const [pageCount, setPageCount] = useState<number>(1);
  const [policies, setPolicies] = useState<Policy[]>([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [categoryName, setCategoryName] = useState<string | undefined>("");

  // eslint-disable-next-line
  const [selectedPolicy, setSelectedPolicyTo] = useState<Policy | undefined>(
    undefined
  );

  useEffect(() => {
    const categories = JSON.parse(
      localStorage.getItem("categories") ?? ""
    ) as CategoryData[];
    const cateId = Number(categoryId);
    const category = categories.find((x) => x.id == cateId);
    setCategoryName(category?.name);
  });

  function closeModal() {
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    async function fetchPolicies() {
      await getPolicies();
    }

    fetchPolicies();
  }, []);

  async function getPolicies(page: number = 1) {
    const resp = await toast.promise(getAllPolicies(categoryId, page), {
      pending: "getting policies",
    });

    setPolicyCount(resp.pageInfo?.totalCount);
    setPageCount(resp.pageInfo?.totalPages ?? 1);
    setPolicies(resp.data);
  }

  const onPageChange = async (page: number) => {
    await getPolicies(page);
    setCurrentPage(page);
  };

  function handlePriceTypeChange(e: ChangeEvent<HTMLSelectElement>): void {
    const copyNewPolicy = { ...newPolicy, policyPriceType: e.target.value };
    setPolicyPriceType(copyNewPolicy.policyPriceType);
  }

  async function handleItemClick(policy: Policy) {
    const { data } = await getAllPolicyCovers(policy.id);
    policy.policyFeatures = data;
    console.log("Policy: ", policy);
    setSelectedPolicyTo(policy);

    setOpenEditModal(true);
  }

  async function handleSavePolicy(): Promise<void> {
    await addNewPolicy({ ...newPolicy })
      .then(() => {
        closeModal();
        //getAllPolicies();
        // No global states
        //window.location.reload()
      })
      .catch(() => {});
  }

  async function handleSaveChanges() {
    const changedData = { ...selectedPolicy };
    // console.log("Updated data: ", changedData);

    let data = {
      policyName: changedData.name,
      categoryId: changedData.categoryId,
      partnerId: changedData.partnerId,
      description: changedData.name,
      policyPriceType: changedData.hasFixedRate ? "Flat" : "Percentage",
      policyPrice: changedData.rate,
      policyType: changedData.policyType,
      coverRequest: selectedPolicy?.policyFeatures
        .filter((x) => x.isActive)
        .map((y) => {
          return {
            featureName: y.feature,
            description: y.description,
            isActive: true,
          };
        }),
    };

    await toast
      .promise(updatePolicy(selectedPolicy?.id, data), {
        pending: "Updating Policy",
        success: "Policy Updated Successfully",
        error: "Error saving Policy changes",
      })
      .catch();

    await getPolicies(currentPage);
  }

  const getPartners = useQuery(
    ["partners"],
    async () => {
      const { data } = await getAllPartners();
      return data;
    },
    { refetchOnWindowFocus: false }
  );

  function updateCheckChange(e: ChangeEvent<HTMLInputElement>, index: number) {
    let policy = selectedPolicy;
    if (policy?.policyFeatures != undefined) {
      policy.policyFeatures[index].isActive = e.target.checked;
      setSelectedPolicyTo({ ...policy });
    }
  }

  function handleDescriptionChange(
    e: ChangeEvent<HTMLInputElement>,
    featureIndex: number
  ) {
    if (selectedPolicy?.policyFeatures != undefined) {
      selectedPolicy.policyFeatures[featureIndex].description = e.target.value;
      setSelectedPolicyTo({ ...selectedPolicy });
    }
  }

  function handleFormChange(e: ChangeEvent<HTMLInputElement>): void {
    var update: any = {};
    update[e.target.name] = e.target.value;
    if (e.target.value.toLowerCase() === "on") {
      update[e.target.name] = true;
    }
    if (e.target.value.toLowerCase() === "off") {
      update[e.target.name] = false;
    }
    const copyNewPolicy = { ...newPolicy, ...update };
    setNewPolicyTo(copyNewPolicy);
  }

  function handleUpdateFormChange(name: string, value: string | boolean) {
    let update: any = {};
    update[name] = value;

    const updatedPolicy = { ...selectedPolicy, ...update };

    setSelectedPolicyTo(updatedPolicy);
  }

  return (
    <>
      <div className="flex-1 bg-gray-100 pl-4 pr-4">
        <PolicySearchBar total={noOfPolicies} getPolicies={getPolicies} />
        <div className="mx-auto mt-[40px] flex-1 w-full h-auto relative items-center justify-center space-x-4">
          <div className="relative overflow-x-auto rounded-xl">
            <table className="w-full text-sm text-left bg-white text-gray-500 dark:text-gray-400">
              <thead className="text-xs border-b bg-white text-blue-700 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-4 ">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Partner
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Has Fixed Rate
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Class
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Rate
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Status
                  </th>
                  <th scope="col" className="relative px-6 py-2"></th>
                </tr>
              </thead>
              <tbody>
                {policies?.map((policy) => (
                  <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                    <th
                      scope="row"
                      className="px-6 py-2 font-medium text-sm text-gray-500 whitespace-nowrap dark:text-white"
                    >
                      {policy.name}
                    </th>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {policy.partnerName}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {policy.hasFixedRate ? (
                        <label className="p-1 px-3 bg-blue-50 block">YES</label>
                      ) : (
                        <label className="p-1 px-3 bg-red-50 block">NO</label>
                      )}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {policy.policyType}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {policy.hasFixedRate ? (
                        <NumericFormat
                          value={policy.rate}
                          displayType={"text"}
                          thousandSeparator={true}
                        />
                      ) : (
                        policy.rate
                      )}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      <button className=" bg-green-200 bg-opacity-20 px-4 rounded-md text-sm text-green-900">
                        Active
                      </button>
                    </td>
                    <td
                      onClick={() => handleItemClick(policy)}
                      className="px-6 py-2 text-sm font-medium text-gray-500 cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="px-6 h-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                        />
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex overflow-x-auto md:justify-center">
          <Pagination
            className=""
            currentPage={currentPage}
            totalPages={pageCount}
            onPageChange={onPageChange}
          />
        </div>
      </div>

      <Transition.Root show={openEditModal} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <div className="fixed inset-0 overflow-y-scroll shadow-2xl">
            <div className="absolute inset-0 overflow-y-scroll">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="min-w-[780px]  transform overflow-y-scroll rounded-lg bg-white p-8 text-left align-middle shadow-sm transition-all">
                    <Dialog.Title
                      as="h3"
                      className="pb-12 w-96 h-6 font-inter font-semibold text-lg leading-tight text-orange-700"
                    >
                      Update - {categoryName} Insurance
                    </Dialog.Title>
                    <div className="w-full min-h-[500px]">
                      <form className="w-full">
                        <div className="grid grid-cols-3 gap-3">
                          <div>
                            <label className="text-gray-700 text-sm">
                              Name
                            </label>
                            <input
                              className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              id="companyName"
                              type="text"
                              placeholder="New Policy Name"
                              value={selectedPolicy?.name}
                              name="updatePolicyName"
                              onChange={(e) =>
                                handleUpdateFormChange("name", e.target.value)
                              }
                            />
                          </div>
                          <div>
                            <label className="text-gray-700 text-sm">
                              Category
                            </label>
                            <br />
                            <select
                              value={
                                getPolicyTypes().find(
                                  (x) => x.value == selectedPolicy?.policyType
                                )?.Id ?? 0
                              }
                              onChange={(e) =>
                                handleUpdateFormChange(
                                  "categoryId",
                                  e.target.value
                                )
                              }
                              name="updateCategoryId"
                              className="bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value={0} disabled selected>
                                Select Policy Category
                              </option>
                              {getPolicyTypes()
                                .filter(
                                  (x) =>
                                    x.type.toLocaleLowerCase() ==
                                    categoryName?.toLowerCase()
                                )
                                .map((cate) => (
                                  <option value={cate.Id}>{cate.label}</option>
                                ))}
                            </select>
                          </div>
                          <div>
                            <label className="text-gray-700 text-sm">
                              Partner
                            </label>
                            <br />
                            <select
                              value={selectedPolicy?.partnerId}
                              name="updatePartnerId"
                              onChange={(e) =>
                                handleUpdateFormChange(
                                  "partnerId",
                                  e.target.value
                                )
                              }
                              className="bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value={0} disabled selected>
                                Select Policy Partner
                              </option>
                              {getPartners.data?.map((part) => (
                                <option value={part.id}>
                                  {part.companyName}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                        <div className="grid grid-cols-3 gap-3 mt-3">
                          <div>
                            <label className="text-gray-700 text-sm">
                              Price Type
                            </label>
                            <br />
                            <select
                              value={
                                selectedPolicy?.hasFixedRate
                                  ? "Flat"
                                  : "Percentage"
                              }
                              name="updatePriceType"
                              onChange={(e) =>
                                handleUpdateFormChange(
                                  "hasFixedRate",
                                  e.target.value == "Flat"
                                )
                              }
                              className="bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            >
                              <option value={0} disabled selected>
                                Select Policy Price Type
                              </option>
                              {["Flat", "Percentage"].map((cate) => (
                                <option value={cate}>{cate}</option>
                              ))}
                            </select>
                          </div>
                          <div>
                            <label className="text-gray-700 text-sm">
                              Price{" "}
                              <span className="bg-yellow-100 text-yellow-800 text-sm font-medium me-2 px-2.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300">
                                {policyPriceType == "Percentage"
                                  ? " Price <= 100 "
                                  : ""}
                              </span>
                            </label>
                            <input
                              className="appearance-none bg-gray-50 rounded-lg w-full py-4 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              id="updatePolicyPrice"
                              type="text"
                              min={0}
                              placeholder="Policy Price"
                              value={selectedPolicy?.rate}
                              name="updatePolicyPrice"
                              onChange={(e) =>
                                handleUpdateFormChange("rate", e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="border-[#ccdcf7] border rounded-md min-h-full w-full mt-5 p-2">
                          <table className="w-full">
                            <thead>
                              <tr className="w-full text-blue-900 font-bold border-b">
                                <td className="w-[10%] text-center border-r p-1">
                                  Select
                                </td>
                                <td className="w-[40%] border-r p-1">
                                  Features
                                </td>
                                <td className="w-[50%] border-l p-1">
                                  Description
                                </td>
                              </tr>
                            </thead>
                            <tbody>
                              {selectedPolicy?.policyFeatures?.map(
                                (feature, index) => (
                                  <tr className="text-[#767676] border-b text-sm">
                                    <td className="grid place-items-center p-2">
                                      <input
                                        onChange={(e) => {
                                          updateCheckChange(e, index);
                                        }}
                                        type="checkbox"
                                        className="checked:bg-orange-500 text-orange-500"
                                        checked={feature.isActive}
                                      />
                                    </td>
                                    <td className="border-l m-2 p-1">
                                      {feature.feature}
                                    </td>
                                    <td className="border-l p-1">
                                      <input
                                        className="appearance-none bg-gray-50 rounded-lg w-full py-2 px-2 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                                        type="text"
                                        placeholder="Feature Description"
                                        value={
                                          selectedPolicy?.policyFeatures[index]
                                            .description
                                        }
                                        onChange={(e) =>
                                          handleDescriptionChange(e, index)
                                        }
                                      />
                                    </td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </form>
                    </div>
                    <div className="grid grid-cols-2 mt-5">
                      <div>
                        <button
                          type="button"
                          className="inline-flex rounded-md float-right border border-transparent bg-blue-900 px-6 py-2 text-sm font-medium text-white hover:bg-blue-800 focus:outline-none focus-visible:ring-2  focus-visible:ring-offset-2"
                          onClick={() => handleSaveChanges()}
                        >
                          Save Changes
                        </button>
                      </div>
                      <div>
                        <span
                          onClick={() => setOpenEditModal(false)}
                          className="mx-4 text-red-600 px-4 underline cursor-pointer"
                        >
                          Cancel
                        </span>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>

      <Toast />
    </>
  );
}

export default InsurancePolicies;
