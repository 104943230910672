import { Fragment, ReactElement, useEffect, useState } from "react";
import UserSearchBar from "../components/userSearchBar";
import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { getAllUsers, getUser } from "../services/api";
import { useQuery } from "@tanstack/react-query";
import { User } from "../types/user";
import { Pagination } from "flowbite-react";

function UserManagement(): ReactElement {
  const [open, setOpen] = React.useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [userId, setUserId] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [totalUsers, setTotalUsers] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  let debug: any;
  const handleItemClick = (id: number) => {
    setOpen(true);
    setUserId(id);
    refetch();
  };

  async function getUsers(currentPage: number = 1) {
    const { data, pageInfo } = await getAllUsers(currentPage);
    setUsers(data);
    setPageCount(pageInfo.totalPages);
    setTotalUsers(pageInfo.totalCount);
  }

  useEffect(() => {
    getUsers();
  }, []);

  const onPageChange = async (page: number) => {
    await getUsers(page);
    setCurrentPage(page);
  };

  const { data, isSuccess, refetch } = useQuery(
    ["user"],
    async () => {
      const { data } = await getUser(userId);
      debug = data;
      return data;
    },
    { enabled: false }
  );

  return (
    <>
      <div className="flex-1 bg-gray-100 px-4">
        <UserSearchBar userCount={totalUsers} />
        <div className="mx-auto mt-4 flex-1 w-full h-auto relative items-center justify-center space-x-4">
          <div className="relative overflow-x-auto rounded-xl">
            <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
              <thead className="text-xs border-b text-orange-700  bg-white dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-4">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Email
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Gender
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Date of Birth
                  </th>
                  <th scope="col" className="px-6 py-4">
                    State
                  </th>
                  <th scope="col" className="px-6 py-4">
                    LGA
                  </th>
                  <th scope="col" className="px-6 py-4">
                    Phone Number
                  </th>
                  <th scope="col" className="px-6 py-4"></th>
                </tr>
              </thead>
              <tbody>
                {users?.map((user) => (
                  <tr
                    onClick={() => handleItemClick(user.id)}
                    className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                  >
                    <th
                      scope="row"
                      className="px-6 py-2 font-medium text-sm text-gray-500 whitespace-nowrap dark:text-white"
                    >
                      {user.firstName} {user.lastName}
                    </th>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {user.email}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {user.gender}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {user.dateOfBirth}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {user.state}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {user.address}
                    </td>
                    <td className="px-6 py-2 text-sm font-medium text-gray-500">
                      {user.phoneNumber}
                    </td>
                    <td>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="px-6 h-8"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M6.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM12.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0zM18.75 12a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                        />
                      </svg>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="flex overflow-x-auto md:justify-center">
          <Pagination
            className=""
            currentPage={currentPage}
            totalPages={pageCount}
            onPageChange={onPageChange}
          />
        </div>
      </div>
      {data !== undefined && isSuccess && (
        <Transition.Root show={open} as={Fragment}>
          <Dialog as="div" className="relative z-10 " onClose={setOpen}>
            <div className="fixed inset-0 overflow-hidden shadow-2xl">
              <div className="absolute inset-0 overflow-hidden">
                <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                  <Transition.Child
                    as={Fragment}
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enterFrom="translate-x-full"
                    enterTo="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leaveFrom="translate-x-0"
                    leaveTo="translate-x-full"
                  >
                    <Dialog.Panel className="pointer-events-auto relative w-[651px]">
                      <div className="flex h-full flex-col overflow-y-scroll bg-white py-8 shadow-xl">
                        <div className="px-12">
                          <Dialog.Title className="w-96 h-6 font-inter font-semibold text-lg leading-tight text-blue-700">
                            {data.firstName} {data.lastName}
                          </Dialog.Title>
                          <p className="font-inter text-blue-700 text-md font-semibold p-2 mt-6 bg-gray-100 border rounded-md">
                            Basic Information
                          </p>
                        </div>
                        <div className="flex flex-row justify-between">
                          <div>
                            <div className="flex flex-col pl-12 pr-24 mt-2 ">
                              <div className="flex flex-col p-2">
                                <div className="relative flex-1 text-md font-semibold rounded-md">
                                  Email
                                </div>
                                <div className="text-gray-700 text-inter">
                                  {data.email}
                                </div>
                              </div>
                              <div className="flex flex-col p-2">
                                <div className="relative flex-1 text-md font-semibold rounded-md">
                                  Gender
                                </div>
                                <div className="text-gray-700 text-inter">
                                  {data.gender}
                                </div>
                              </div>
                              <div className="flex flex-col p-2">
                                <div className="relative flex-1 text-md font-semibold rounded-md">
                                  Phone Number
                                </div>
                                <div className="text-gray-700 text-inter">
                                  {data.phoneNumber}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="flex flex-col pl-12 pr-24 mt-2 ">
                              <div className="flex flex-col p-2">
                                <div className="relative flex-1 text-md font-semibold rounded-md">
                                  Date of Birth
                                </div>
                                <div className="text-gray-700 text-inter">
                                  {data.dateOfBirth}
                                </div>
                              </div>
                              <div className="flex flex-col p-2">
                                <div className="relative flex-1 text-md font-semibold rounded-md">
                                  State
                                </div>
                                <div className="text-gray-700 text-inter">
                                  {data.state}
                                </div>
                              </div>
                              <div className="flex flex-col p-2 ">
                                <div className="relative flex-1 text-md font-semibold rounded-md">
                                  LGA
                                </div>
                                <div className="text-gray-700 text-inter">
                                  {data.lga}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <hr className="mx-6"></hr>
                        <p className="font-inter text-blue-700 text-md font-semibold p-2 mx-12 mt-6 bg-gray-100 border rounded-md">
                          Document Uploaded (1)
                        </p>
                        <div className="flex flex-row px-16 py-6">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth="1.5"
                            stroke="currentColor"
                            className="w-8 h-8 mt-2 text-red-700"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m0 12.75h7.5m-7.5 3H12M10.5 2.25H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
                            />
                          </svg>
                          <span className="pl-4 text-gray-700 text-inter">
                            National Identification Number (NIN) <br />
                            06/06/2022, 11:52:53, 1.0 MB
                          </span>
                        </div>
                        <hr className="mx-6 my-2"></hr>
                        <p className="font-inter text-blue-700 text-md font-semibold p-2 mx-12 my-2 bg-gray-100 border rounded-md">
                          Current plans
                        </p>
                        <div className="flex flex-row mt-2 px-16 space-x-5">
                          <img
                            src={require("../../../assets/images/leadway.png")}
                            alt="Chebe Logo"
                            className="w-[74px] h-[56px]"
                          />
                          <div className="flex-1 flex-col">
                            <span className="text-gray-700 text-inter">
                              Leadway Third Party
                            </span>
                            <div className="flex items-center bg-gray-100 rounded-md w-fit px-4 py-1">
                              <img
                                src={require("../../../assets/images/shield.png")}
                                alt="Chebe Logo"
                                className="w-[12px] h-[14px] mr-2"
                              />
                              <span className="text-blue-700 text-inter font-normal">
                                Car
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col items-center">
                            <div className="text-md font-semibold">Status</div>
                            <div className="bg-green-100 rounded-md py-1 px-4 text-inter font-normal text-green-700">
                              Active
                            </div>
                          </div>
                        </div>
                        <hr className="mx-6 my-3"></hr>
                        <div className="flex flex-row mt-2 px-16 space-x-5">
                          <img
                            src={require("../../../assets/images/leadway.png")}
                            alt="Chebe Logo"
                            className="w-[74px] h-[56px]"
                          />
                          <div className="flex-1 flex-col">
                            <span className="text-gray-700 text-inter">
                              Leadway Third Party
                            </span>
                            <div className="flex items-center bg-gray-100 rounded-md w-fit px-4 py-1">
                              <img
                                src={require("../../../assets/images/shield.png")}
                                alt="Chebe Logo"
                                className="w-[12px] h-[14px] mr-2"
                              />
                              <span className="text-blue-700 text-inter font-normal">
                                Car
                              </span>
                            </div>
                          </div>
                          <div className="flex flex-col items-center">
                            <div className="text-md font-semibold">Status</div>
                            <div className="bg-red-100 rounded-md py-1 px-4 text-inter font-normal text-red-700">
                              Expired
                            </div>
                          </div>
                        </div>
                        <hr className="mx-6 my-3"></hr>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
}

export default UserManagement;